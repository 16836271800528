'use strict';
app.factory("signalRService", ['$rootScope', '$http', 'authService', 'httpService', '$q', function ($rootScope, $http, authService, httpService, $q)
{
    let signalRServiceFactory = {};

    let startKeepAlive = false;

    let _mainSignalR = {
        connection: $.hubConnection($rootScope.httpName + $rootScope.quartoDNS),
        chatHub: null,
        pingInterval: null,
        transport: ['webSockets', 'serverSentEvents', 'longPolling'],
        authData: null,
        isGracefulDisconnect: false,
        isStarting: false,
        startingPoint: null
    };

    let _workflowSignalR = {
        connection: $.hubConnection($rootScope.httpName + $rootScope.workflowDNS),
        chatHub: null,
        pingInterval: null,
        transport: ['webSockets', 'serverSentEvents', 'longPolling'],
        authData: null,
        isGracefulDisconnect: false,
        isStarting: false,
        startingPoint: null
    };

    let _procurementSignalR = {
        connection: $.hubConnection($rootScope.httpName + $rootScope.procureDNS),
        chatHub: null,
        pingInterval: null,
        transport: ['webSockets', 'serverSentEvents', 'longPolling'],
        authData: null,
        isGracefulDisconnect: false,
        isStarting: false,
        startingPoint: null
    };

    //define hub proxy
    function _InitializeMainHubProxy()
    {
        try
        {
            //clean up first to stop all previous activity
            if (_mainSignalR.chatHub != null)
            {
                _mainSignalR.chatHub.off('broadcastUpdateMaster', null);
                _mainSignalR.chatHub.off('receiveRefresh', null);
                _mainSignalR.chatHub.off('getAppVersion', null);
                _mainSignalR.chatHub.off('clearMessage', null);
                _mainSignalR.chatHub.off('receiveMessage', null);
                _mainSignalR.chatHub.off('forcedReloadClient', null);
                _mainSignalR.chatHub.off('forcedLogoutClient', null);
                _mainSignalR.chatHub.off('collectJSPerfLog', null);
                _mainSignalR.chatHub.off('onUsageAnalytics', null);
                _mainSignalR.chatHub = null;

                $(_mainSignalR.connection).off("onDisconnect");
                $(_mainSignalR.connection).off("onConnectionSlow");
                $(_mainSignalR.connection).off("onReconnect");

                _mainSignalR.connection.qs = null;
                $.signalR.ajaxDefaults.headers = void 0;
                _mainSignalR.authData = null;
                delete _mainSignalR.connection.proxies.quartochathub;

                _mainSignalR.connection = $.hubConnection($rootScope.httpName + $rootScope.quartoDNS);
            }
            
            let authData = authService.authentication;
            let token = authData.token;

            if (!token)
            {
                _mainSignalR.authData = null;
                console.log("failed to join chat!invalid token!");
                return false;
            }
            else
            {
                _mainSignalR.authData = authData;

                $.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };

                _mainSignalR.chatHub = _mainSignalR.connection.createHubProxy('quartoChatHub');
                _mainSignalR.connection.logging = false;
                _mainSignalR.connection.qs = { "token": "Bearer " + token };

                _mainSignalR.chatHub.on('clearMessage', function ()
                {
                    $rootScope.notifications = [];
                });

                _mainSignalR.chatHub.on('receiveMessage', function (id, senderEmail, senderName, messageHeader, messageDetail, sentDate, menuUrl, jsControllerUrl, parameters)
                {
                    if (messageDetail.contains("l#a#r#g#e@f@o@r@m@a@t"))
                    {
                        $http({
                            url: $rootScope.httpName + $rootScope.quartoDNS + "/api/notify/RetrieveLargeMessage",
                            method: "POST",
                            data: id,
                            headers: { 'Content-Type': 'application/json' }
                        }).then(function (data)
                        {
                            let constructedMessage = { key: id, title: messageHeader, detail: JSON.parse(data.data), name: senderName, email: senderEmail, date: sentDate, menuUrl: menuUrl, jsControllerUrl: jsControllerUrl, parameters: parameters };
                            $rootScope.notifications.push(constructedMessage);

                            for (var i = 0; i < $rootScope.notifications.length; i++)
                            {
                                $rootScope.notifications[i].selected = false;
                            }

                            $rootScope.notifications[$rootScope.notifications.length - 1].selected = true;
                            $rootScope.$emit("SignalRReceiveMsg", $rootScope.notifications[$rootScope.notifications.length - 1]);
                        }, function () { });
                    }
                    else
                    {
                        let constructedMessage = { key: id, title: messageHeader, detail: messageDetail, name: senderName, email: senderEmail, date: sentDate, menuUrl: menuUrl, jsControllerUrl: jsControllerUrl, parameters: parameters };
                        $rootScope.notifications.push(constructedMessage);

                        for (var i = 0; i < $rootScope.notifications.length; i++)
                        {
                            $rootScope.notifications[i].selected = false;
                        }

                        $rootScope.notifications[$rootScope.notifications.length - 1].selected = true;
                        $rootScope.$emit("SignalRReceiveMsg", $rootScope.notifications[$rootScope.notifications.length - 1]);
                    }
                });

                _mainSignalR.chatHub.on('broadcastUpdateMaster', function (result)
                {
                    try
                    {
                        let testJSON = JSON.parse(result);

                        if (typeof testJSON !== "object")
                        {
                            throw "invalid json response!";
                        }
                        else
                        {
                            let strTypes = "";
                            if (typeof testJSON !== "undefined")
                            {
                                if (typeof testJSON.length !== "undefined")
                                {
                                    for (var i = 0; i < testJSON.length; i++)
                                    {
                                        if (testJSON[i].type !== "")
                                        {
                                            if (strTypes === "")
                                            {
                                                strTypes = testJSON[i].type;
                                            }
                                            else
                                            {
                                                strTypes = strTypes + "," + testJSON[i].type;
                                            }
                                        }
                                        else
                                        {
                                            if (strTypes === "")
                                            {
                                                strTypes = "ALL";
                                            }
                                            else
                                            {
                                                strTypes = strTypes + "ALL";
                                            }
                                        }
                                    }
                                }
                            }

                            console.log("%cReceive server update : Receive success!(" + strTypes + ")", 'background:red;color:#fff');
                            httpService.updateDataSource(testJSON);
                        }
                    }
                    catch (ex)
                    {
                        console.log("%cReceive server update : Receive failed!Invalid response!Flush all!", 'background:red;color:#fff');
                        httpService.updateDataSource([{ updatetype: "", type: "", result: "" }]);
                    }
                });

                _mainSignalR.chatHub.on('forcedReloadClient', function (result)
                {
                    httpService.forcedReloadClient(result);
                });

                _mainSignalR.chatHub.on('forcedLogoutClient', function ()
                {
                    //force everyone to logout
                    $rootScope.$emit("SignalRLogout", {});
                });

                _mainSignalR.chatHub.on('collectJSPerfLog', function ()
                {
                    httpService.postPerfDBLog();
                });

                _mainSignalR.chatHub.on('getAppVersion', function (version)
                {
                    _onAppVersion(version, "ChatHub");
                });

                _mainSignalR.chatHub.on('receiveRefresh', function (UserKeys)
                {
                    if (typeof UserKeys !== "undefined" && Array.isArray(UserKeys) && UserKeys.length > 0)
                    {
                        if (UserKeys.findIndex(function (item)
                        {
                            return (+item) === (+_mainSignalR.authData.userKey);
                        }) > -1)
                        {
                            $rootScope.$broadcast("receiveRefresh", true);
                        }
                    }
                });

                _mainSignalR.chatHub.on('onUsageAnalytics', function (data)
                {
                    $rootScope.$broadcast("onUsageAnalytics", data)
                });

                _mainSignalR.connection.disconnected(function ()
                {
                    if (_mainSignalR.isGracefulDisconnect)
                    {
                        //stopped gracefully, ignore
                    }
                    else
                    {
                        //check if there is internet connection
                        if (window.navigator.onLine)
                        {
                            _startMainSignalR();
                        }
                        else
                        {
                            if (!window.ononline)
                                window.ononline = function ()
                                {
                                    _startMainSignalR();
                                };
                        }
                    }
                });

                _mainSignalR.connection.connectionSlow(function ()
                {
                    console.log("slow signalR connection detected.")
                });

                _mainSignalR.connection.reconnected(function ()
                {
                    window.ononline = null;
                    _mainSignalR.chatHub.invoke("joinChat", authData.token, authData.clientID, authData.email, authData.userKey.toString()).then(function ()
                    {
                        D(() => console.info("on chatHub joinChat (reconnected)"));
                        _checkAppVersion();
                        httpService.updateDataSource([{ updatetype: "", type: "", result: "" }]);
                        $rootScope.$broadcast("onChatHubReconnected");
                    }, function ()
                    {
                        _startMainSignalR();
                    });
                });

                return true;
            }
        }
        catch (err)
        {
            return false;
        }
    }

    function _InitializeWorkflowHubProxy()
    {
        try
        {
            //clean up first to stop all previous activity
            if (_workflowSignalR.chatHub != null)
            {
                _workflowSignalR.chatHub = null;

                $(_workflowSignalR.connection).off("onDisconnect");
                $(_workflowSignalR.connection).off("onConnectionSlow");
                $(_workflowSignalR.connection).off("onReconnect");

                _workflowSignalR.connection.qs = null;
                //$.signalR.ajaxDefaults.headers = void 0;
                _workflowSignalR.authData = null;
                delete _workflowSignalR.connection.proxies.workflowchathub;

                _workflowSignalR.connection = $.hubConnection($rootScope.httpName + $rootScope.workflowDNS);
            }

            let authData = authService.authentication;
            let token = authData.token;

            if (!token)
            {
                _workflowSignalR.authData = null;
                console.log("failed to join chat!invalid token!");
                return false;
            }
            else
            {
                _workflowSignalR.authData = authData;

                //$.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };

                _workflowSignalR.chatHub = _workflowSignalR.connection.createHubProxy('workflowChatHub');
                _workflowSignalR.connection.logging = false;
                _workflowSignalR.connection.qs = { "token": "Bearer " + token };

                return true;
            }
        }
        catch (err)
        {
            return false;
        }
    }

    function _InitializeProcurementHubProxy()
    {
        try
        {
            //clean up first to stop all previous activity
            if (_procurementSignalR.chatHub != null)
            {
                _procurementSignalR.chatHub = null;

                $(_procurementSignalR.connection).off("onDisconnect");
                $(_procurementSignalR.connection).off("onConnectionSlow");
                $(_procurementSignalR.connection).off("onReconnect");

                _procurementSignalR.connection.qs = null;
                //$.signalR.ajaxDefaults.headers = void 0;
                _procurementSignalR.authData = null;
                delete _procurementSignalR.connection.proxies.procurementchathub;

                _procurementSignalR.connection = $.hubConnection($rootScope.httpName + $rootScope.procureDNS);
            }

            let authData = authService.authentication;
            let token = authData.token;

            if (!token)
            {
                _procurementSignalR.authData = null;
                console.log("failed to join chat!invalid token!");
                return false;
            }
            else
            {
                _procurementSignalR.authData = authData;

                //$.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };

                _procurementSignalR.chatHub = _procurementSignalR.connection.createHubProxy('procurementChatHub');
                _procurementSignalR.connection.logging = false;
                _procurementSignalR.connection.qs = { "token": "Bearer " + token };

                return true;
            }
        }
        catch (err)
        {
            return false;
        }
    }

    let _startMainSignalR = function ()
    {
        if (_mainSignalR.isStarting)
        {
            return _mainSignalR.startingPoint;
        }
        else
        {
            let defer = $q.defer();

            _mainSignalR.isStarting = true;
            _mainSignalR.startingPoint = defer.promise;

            try
            {
                _stopMainSignalR(true).then(function ()
                {
                    if (_InitializeMainHubProxy())
                    {
                        //throw new Error("DIEEEE!!");
                        _mainSignalR.connection.start({ pingInterval: _mainSignalR.pingInterval, transport: _mainSignalR.transport, waitForPageLoad: false }).done(function ()
                        {
                            $rootScope.notificationReady = true;

                            //Join & Get Notification
                            _mainSignalR.chatHub.invoke('joinChat', _mainSignalR.authData.token, _mainSignalR.authData.clientID, _mainSignalR.authData.email, _mainSignalR.authData.userKey.toString()).then(function ()
                            {
                                D(() => console.info("on chatHub joinChat"));
                                _mainSignalR.isGracefulDisconnect = false;
                                _checkAppVersion();
                                window.ononline = null;

                                //load everything in indexeddb into variable
                                httpService.updateDataSource([{ updatetype: "", type: "", result: "" }]);

                                _mainSignalR.isStarting = false;
                                defer.resolve();
                            }, function (err)
                            {
                                try
                                {
                                    _mainSignalR.isGracefulDisconnect = true;

                                    if (_mainSignalR.connection.state !== $.signalR.connectionState.disconnected)
                                    {
                                        _mainSignalR.connection.stop(false, true);
                                        console.log("%cSignalR stopped!", 'background:blue;color:#fff');
                                    }
                                }
                                catch { }

                                console.log("Failed to join chathub!");
                                console.log(err);

                                _mainSignalR.isStarting = false;
                                defer.reject();
                            });
                        }).fail(function (err)
                        {
                            console.log("Main connection failed to start!");
                            console.log(err);

                            _mainSignalR.isStarting = false;
                            defer.reject();
                        });
                    }
                    else
                    {
                        _mainSignalR.isStarting = false;
                        defer.reject();
                    }
                }, function ()
                {
                    _mainSignalR.isStarting = false;
                    defer.reject();
                });
            }
            catch (err)
            {
                _mainSignalR.isStarting = false;
                defer.reject();
            }

            return defer.promise;
        }
    }

    let _startSignalR = function (type)
    {
        if (type === "workflow")
        {
            if (_workflowSignalR.isStarting)
            {
                return _workflowSignalR.startingPoint;
            }
            else
            {
                let defer = $q.defer();

                _workflowSignalR.isStarting = true;
                _workflowSignalR.startingPoint = defer.promise;

                _stopSignalR(type).then(function ()
                {
                    if (_InitializeWorkflowHubProxy())
                    {
                        _workflowSignalR.connection.start({ pingInterval: _workflowSignalR.pingInterval, transport: _workflowSignalR.transport, waitForPageLoad: false }).done(function ()
                        {
                            _workflowSignalR.isGracefulDisconnect = false;
                            _workflowSignalR.isStarting = false;
                            defer.resolve();
                        }).fail(function (err)
                        {
                            console.log("Workflow connection failed to start!");
                            console.log(err);

                            _workflowSignalR.isStarting = false;
                            defer.reject();
                        });
                    }
                    else
                    {
                        _workflowSignalR.isStarting = false;
                        defer.reject();
                    }
                }, function ()
                {
                    _workflowSignalR.isStarting = false;
                    defer.reject();
                });

                return defer.promise;
            }
        }
        else if (type === "procurement")
        {
            if (_procurementSignalR.isStarting)
            {
                return _procurementSignalR.startingPoint;
            }
            else
            {
                let defer = $q.defer();

                _procurementSignalR.isStarting = true;
                _procurementSignalR.startingPoint = defer.promise;

                _stopSignalR(type).then(function ()
                {
                    if (_InitializeProcurementHubProxy())
                    {
                        _procurementSignalR.connection.start({ pingInterval: _procurementSignalR.pingInterval, transport: _procurementSignalR.transport, waitForPageLoad: false }).done(function ()
                        {
                            _procurementSignalR.isGracefulDisconnect = false;
                            _procurementSignalR.isStarting = false;
                            defer.resolve();
                        }).fail(function (err)
                        {
                            console.log("Procurement connection failed to start!");
                            console.log(err);

                            _procurementSignalR.isStarting = false;
                            defer.reject();
                        });
                    }
                    else
                    {
                        _procurementSignalR.isStarting = false;
                        defer.reject();
                    }
                }, function ()
                {
                    _procurementSignalR.isStarting = false;
                    defer.reject();
                });

                return defer.promise;
            }
        }
        else if (type === "main")
        {
            let defer = $q.defer();

            _startMainSignalR().then(function ()
            {
                defer.resolve();
            }, function ()
            {
                defer.reject();
            });

            return defer.promise;
        }
        else
        {
            let defer = $q.defer();

            defer.reject();

            return defer.promise;
        }
    }

    let _stopMainSignalR = function ()
    {
        let defer = $q.defer();

        try
        {
            _mainSignalR.isGracefulDisconnect = true;

            if (_mainSignalR.connection.state !== $.signalR.connectionState.disconnected)
            {
                _mainSignalR.connection.stop(false, true);
                console.log("%cSignalR stopped!", 'background:blue;color:#fff');
                defer.resolve();
            }
            else
            {
                defer.resolve();
            }
        }
        catch (err)
        {
            console.log(err);
            defer.reject();
        }

        return defer.promise;
    }

    let _stopSignalR = function (type)
    {
        let defer = $q.defer();

        try
        {
            if (type === "workflow")
            {
                _workflowSignalR.isGracefulDisconnect = true;

                if (_workflowSignalR.connection.state !== $.signalR.connectionState.disconnected)
                {
                    _workflowSignalR.connection.stop(false, true);
                }

                defer.resolve();
            }
            else if (type === "procurement")
            {
                _procurementSignalR.isGracefulDisconnect = true;

                if (_procurementSignalR.connection.state !== $.signalR.connectionState.disconnected)
                {
                    _procurementSignalR.connection.stop(false, true);
                }

                defer.resolve();
            }
            else if (type === "main")
            {
                _stopMainSignalR().then(function ()
                {
                    defer.resolve();
                }, function ()
                {
                    defer.reject();
                });
            }
            else
            {
                defer.reject();
            }
        }
        catch (err)
        {
            console.log(err);
            defer.reject();
        }

        return defer.promise;
    }

    /**
     * @param {string} version
     * @param {string} source - for debugging purpose, to know where the version is coming from    */
    let _onAppVersion = function (version, source = "unknown") 
    {
        if (_.isNil(version))
        {
            D(() => console.info("on ChatHubAppVersion, source:", source,
                "server:", version,
                "local:", $rootScope.chatHubAppVersion,
                "action:", "nothing"));

            //no response from server, do nothing
        }
        else if (_.isNil($rootScope.chatHubAppVersion))
        {
            D(() => console.info("on ChatHubAppVersion, source:", source,
                "server:", version,
                "local:", $rootScope.chatHubAppVersion,
                "action:", "assign version"));

            //local do not have version, assign from server
            $rootScope.chatHubAppVersion = version;
        }
        else if ($rootScope.chatHubAppVersion !== version)
        {
            D(() => console.info("on ChatHubAppVersion, source:", source,
                "server:", version,
                "local:", $rootScope.chatHubAppVersion,
                "action:", "prompt refresh"));

            //local have version, but different from server, prompt refresh (delay 5s as workaround to allow mainController to load first)
            setTimeout(() => httpService.broadcast("openWinVersion", true), 5000);
        }
    }

    let _checkAppVersion = function ()
    {
        try
        {
            if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
            {
                // use signalR to check for ChatHub app version is more resource friendly.
                _mainSignalR.chatHub.invoke('getAppVersion');
            } else
            {
                // Fallback to HTTP GET to check for ChatHub app version.
                (async () =>
                {
                    try
                    {
                        const appVersionApi = $rootScope.quartoWebApiurl + "ChatHubAppVersion/Get";
                        const version = (await (await fetch(appVersionApi)).json()).Version;
                        _onAppVersion(version, "HttpGet");
                    } catch (err)
                    {
                        console.error("httpGet ChatHubAppVersion", err);
                    }
                })();
            }
        }
        catch (ex)
        {
            console.error(ex);
        }
    };

    let _sendMsg = function (a, b, c, d, e, f)
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('sendMessage', a, b, c, d, e, f).then(function ()
            {
                //success then nothing to do anymore
            }, function ()
            {
                _startMainSignalR().then(function ()
                {
                    _mainSignalR.chatHub.invoke('sendMessage', a, b, c, d, e, f);
                });
            });
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('sendMessage', a, b, c, d, e, f);
            });
        }
    }

    let _clearMsg = function (a)
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('clearAll', a);
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('clearAll', a);
            });
        }
    }

    let _clearSpecificMsg = function (a, b)
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('clearNotification', a, b);
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('clearNotification', a, b);
            });
        }
    }

    let _getUsageAnalytics = function ()
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('getUsageAnalytics').then(function (data)
            {
                $rootScope.$broadcast("onUsageAnalytics", data)
            });
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('getUsageAnalytics').then(function (data)
                {
                    $rootScope.$broadcast("onUsageAnalytics", data)
                });
            });
        }
    }

    let _postUsageAnalytics = function (menuTableName, menuKey)
    {
        if (_.isEmpty(menuTableName) || !_.isNumber(menuKey))
        {
            return;
        }

        let defer = $q.defer();

        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('postUsageAnalytics', menuTableName, menuKey).then(function ()
            {
                defer.resolve();
            }, function ()
            {
                defer.reject()
            })
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('postUsageAnalytics', menuTableName, menuKey).then(function ()
                {
                    defer.resolve();
                }, function ()
                {
                    defer.reject()
                })
            }, function ()
            {
                defer.reject()
            });
        }

        return defer.promise;
    }

    let _postReportExecution = function (sessionID, RenderingStart, FirstPageLoad, RenderingEnd, PageCount)
    {
        let defer = $q.defer();

        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('postReportExecutionInformation', sessionID, RenderingStart, FirstPageLoad, RenderingEnd, PageCount).then(function ()
            {
                defer.resolve();
            }, function ()
            {
                defer.reject()
            })
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('postReportExecutionInformation', sessionID, RenderingStart, FirstPageLoad, RenderingEnd, PageCount).then(function ()
                {
                    defer.resolve();
                }, function ()
                {
                    defer.reject()
                })
            }, function ()
            {
                defer.reject()
            });
        }

        return defer.promise;
    }

    let _postFormUsage = function (formName, MenuKey)
    {
        try
        {
            let authData = authService.authentication;
            if (!!authData.token)
            {
                if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
                {
                    _mainSignalR.chatHub.invoke('postFormUsage', formName.toString(), MenuKey.toString(), authData.clientID.toString(), authData.userKey.toString());
                }
                else
                {
                    _startMainSignalR().then(function ()
                    {
                        _mainSignalR.chatHub.invoke('postFormUsage', formName.toString(), MenuKey.toString(), authData.clientID.toString(), authData.userKey.toString());
                    });
                }
            }
        }
        catch (err) { }
    };

    let _postScreenSize = function ()
    {
        //selectively sample
        if (Math.floor(Math.random() * 2) == 0 || Math.floor(Math.random() * 2) == 0)
        {
            return;
        }

        try
        {
            let authData = authService.authentication;
            if (!!authData.token && !!window && !!window.screen)
            {
                if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
                {
                    _mainSignalR.chatHub.invoke('postScreenSize', window.screen.height.toString(), window.screen.width.toString(), authData.clientID.toString(), authData.userKey.toString());
                }
                else
                {
                    _startMainSignalR().then(function ()
                    {
                        _mainSignalR.chatHub.invoke('postScreenSize', window.screen.height.toString(), window.screen.width.toString(), authData.clientID.toString(), authData.userKey.toString());
                    });
                }
            }
        }
        catch (err) { }
    };

    let _postNetSpeed = function ()
    {
        //selectively sample
        if (Math.floor(Math.random() * 2) == 0 || Math.floor(Math.random() * 2) == 0)
        {
            return;
        }

        //delay for 60s
        setTimeout(() =>
        {
            try
            {
                let authData = authService.authentication;
                if (!!authData.token && !!window && !!window.screen)
                {
                    if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
                    {
                        //latency test
                        let startTime = performance.now();
                        $http.get("/connection-test", { timeout: 60000 }).then(() =>
                        {
                            let elapsedms_latency = (performance.now() - startTime);

                            //load test
                            startTime = performance.now();
                            $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/SpeedTest/GetPayload", { timeout: 60000 }).then(() =>
                            {
                                let elapsedms_load = (performance.now() - startTime);

                                if (elapsedms_latency > 0 && elapsedms_load > 0 && elapsedms_load >= elapsedms_latency)
                                {
                                    D(() => console.log("Communication Test to Quarto API: Response round trip took " + elapsedms_latency.toString() + " ms"));
                                    D(() => console.log("Communication Test to Quarto API: 5MB package retrieval took " + elapsedms_load.toString() + " ms (Estimate: " + ((5000000 / (elapsedms_load / 1000)) / 125000).NtoDP(4).toString() + " Mbps)"));

                                    _mainSignalR.chatHub.invoke('postNetSpeed', ((5000000 / (elapsedms_load / 1000)) / 125000).NtoDP(4).toString(), elapsedms_latency.NtoDP(4).toString(), authData.clientID.toString(), authData.userKey.toString());
                                }
                            });
                        });
                    }
                    else
                    {
                        _startMainSignalR().then(function ()
                        {
                            setTimeout(() =>
                            {
                                //latency test
                                let startTime = performance.now();
                                $http.get("/connection-test", { timeout: 60000 }).then(() =>
                                {
                                    let elapsedms_latency = (performance.now() - startTime);

                                    //load test
                                    startTime = performance.now();
                                    $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/SpeedTest/GetPayload", { timeout: 60000 }).then(() =>
                                    {
                                        let elapsedms_load = (performance.now() - startTime);

                                        if (elapsedms_latency > 0 && elapsedms_load > 0 && elapsedms_load >= elapsedms_latency)
                                        {
                                            D(() => console.log("Communication Test to Quarto API: Response round trip took " + elapsedms_latency.toString() + " ms"));
                                            D(() => console.log("Communication Test to Quarto API: 5MB package retrieval took " + elapsedms_load.toString() + " ms (Estimate: " + ((5000000 / (elapsedms_load / 1000)) / 125000).NtoDP(4).toString() + " Mbps)"));

                                            _mainSignalR.chatHub.invoke('postNetSpeed', ((5000000 / (elapsedms_load / 1000)) / 125000).NtoDP(4).toString(), elapsedms_latency.NtoDP(4).toString(), authData.clientID.toString(), authData.userKey.toString());
                                        }
                                    });
                                });
                            }, 60000);
                        });
                    }
                }
            }
            catch (err) { }
        }, 60000);
    };

    let _getProcStat = function (processID)
    {
        let defer = $q.defer();

        if (_procurementSignalR.connection.state === $.signalR.connectionState.disconnected)
        {
            _startSignalR("procurement").then(function ()
            {
                _procurementSignalR.chatHub.invoke('getMsg', processID).then(function (data)
                {
                    if (data.startsWith("service_"))
                    {
                        defer.resolve({
                            type: "service",
                            rawData: tmp
                        });
                    }
                    else
                    {
                        //no messages yet
                        defer.resolve({
                            type: "blank",
                            rawData: {}
                        });
                    }
                });
            }, function ()
            {
                defer.reject();
            });
        }
        else if (_procurementSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _procurementSignalR.chatHub.invoke('getMsg', processID).then(function (data)
            {
                if (data.startsWith("service_"))
                {
                    try
                    {
                        let test = data.substr(8);
                    }
                    catch (ex)
                    {
                        console.log(data);
                    }

                    defer.resolve({
                        type: "service",
                        rawData: angular.copy(data.substr(8))
                    });
                }
                else
                {
                    //no messages yet
                    defer.resolve({
                        type: "blank",
                        rawData: {}
                    });
                }
            });
        }
        else
        {
            console.log("connecting or re-connecting, no solution yet.");
            defer.reject();
        }

        return defer.promise;
    };

    let _getWorkFlowStat = function (processID)
    {
        let defer = $q.defer();

        if (_workflowSignalR.connection.state === $.signalR.connectionState.disconnected)
        {
            _startSignalR("workflow").then(function ()
            {
                _workflowSignalR.chatHub.invoke('getMsg', processID).then(function (data)
                {
                    if (data.startsWith("service_"))
                    {
                        try
                        {
                            //in case null causing parse fails
                            let tmp = angular.copy(JSON.parse(data.substr(8)));

                            defer.resolve({
                                type: "service",
                                rawData: tmp
                            });
                        }
                        catch (ex)
                        {
                            defer.reject();
                        }
                    }
                    else if (data.startsWith("progress_"))
                    {
                        defer.resolve({
                            type: "progress",
                            rawData: angular.copy(data.substr(9))
                        });
                    }
                    else
                    {
                        //no messages yet
                        defer.resolve({
                            type: "blank",
                            rawData: {}
                        });
                    }
                });
            }, function ()
            {
                defer.reject();
            });
        }
        else if (_workflowSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _workflowSignalR.chatHub.invoke('getMsg', processID).then(function (data)
            {
                if (data.startsWith("service_"))
                {
                    try
                    {
                        let test = JSON.parse(data.substr(8))
                    }
                    catch (ex)
                    {
                        console.log(data);
                    }

                    defer.resolve({
                        type: "service",
                        rawData: angular.copy(JSON.parse(data.substr(8)))
                    });
                }
                else if (data.startsWith("progress_"))
                {
                    defer.resolve({
                        type: "progress",
                        rawData: angular.copy(data.substr(9))
                    });
                }
                else
                {
                    //no messages yet
                    defer.resolve({
                        type: "blank",
                        rawData: {}
                    });
                }
            });
        }
        else
        {
            console.log("connecting or re-connecting, no solution yet.");
            defer.reject();
        }

        return defer.promise;
    };

    let _checkAlive = function ()
    {
        var deferred = $q.defer();

        let authData = authService.authentication;

        if (!!authData.token)
        {
            //console.log("Check Session");
            if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
            {
                try
                {
                    _mainSignalR.chatHub.invoke('checkAlive', authData.token).then(function (result)
                    {
                        if (result === true)
                        {
                            deferred.resolve();
                        }
                        else
                        {
                            deferred.reject("Could not find matching token.");
                        }
                    }, function ()
                    {
                        $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/UserAccess/CheckAlive?token=" + authData.token, { timeout: 60000 }).success(function (response)
                        {
                            if (JSON.parse(response) !== true)
                            {
                                deferred.reject("Could not find matching token.");
                            }
                            else
                            {
                                deferred.resolve();
                            }
                        }).error(function (err, status)
                        {
                            if (status == 0)
                            {
                                deferred.reject("Fail to connect to server");
                            }
                            else
                            {
                                deferred.reject(err);
                            }
                        });
                    });
                }
                catch (ex)
                {
                    $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/UserAccess/CheckAlive?token=" + authData.token, { timeout: 60000 }).success(function (response)
                    {
                        if (JSON.parse(response) !== true)
                        {
                            deferred.reject("Could not find matching token.");
                        }
                        else
                        {
                            deferred.resolve();
                        }
                    }).error(function (err, status)
                    {
                        if (status == 0)
                        {
                            deferred.reject("Fail to connect to server");
                        }
                        else
                        {
                            deferred.reject(err);
                        }
                    });
                }
            }
            else
            {
                $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/UserAccess/CheckAlive?token=" + authData.token, { timeout: 60000 }).success(function (response)
                {
                    if (JSON.parse(response) !== true)
                    {
                        deferred.reject("Could not find matching token.");
                    }
                    else
                    {
                        deferred.resolve();
                    }
                }).error(function (err, status)
                {
                    if (status == 0)
                    {
                        deferred.reject("Fail to connect to server");
                    }
                    else
                    {
                        deferred.reject(err);
                    }
                });
            }
        }
        else
        {
            deferred.reject("Could not find matching token.");
        }

        return deferred.promise;
    }

    function random()
    {
        //ideally should be 180000
        let min = 160000;
        let max = 190000;

        return (min + Math.random() * (max - min)).NtoDP(0);
    };

    function randomTick()
    {
        if (startKeepAlive)
        {
            setTimeout(() =>
            {
                _checkAlive().then(function ()
                {
                    //console.log("checking alive success");
                    randomTick();
                }, function ()
                {
                    //console.log("checking alive failed");
                    randomTick();
                });
            }, random());
        }
    };

    let _keepAlive = function (isStart)
    {
        try
        {
            if (isStart === true && startKeepAlive === true)
            {
                //do nothing, already started
            }
            else
            {
                startKeepAlive = isStart;

                randomTick();
            }
        }
        catch (ex) { }
    };

    let _refreshSession = function ()
    {
        let defer = $q.defer();

        _keepAlive(false);
        _checkAlive().then(function ()
        {
            _startMainSignalR().then(function ()
            {
                _keepAlive(true);
                _postScreenSize();
                _postNetSpeed();

                defer.resolve();
            }, function (err)
            {
                _keepAlive(true);
                _postScreenSize();
                _postNetSpeed();

                defer.resolve();
            });
        }, function ()
        {
            defer.reject();
        });

        return defer.promise;
    };

    let _broadcastRefresh = function (UserGrpKeys)
    {
        let defer = $q.defer();

        if (typeof UserGrpKeys !== "undefined" && Array.isArray(UserGrpKeys) && UserGrpKeys.length > 0)
        {
            let localUserGrpKeys = UserGrpKeys;

            if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
            {
                _mainSignalR.chatHub.invoke('broadcastRefresh', localUserGrpKeys).then(function ()
                {
                    defer.resolve();
                }, function ()
                {
                    defer.reject()
                });
            }
            else
            {
                _startMainSignalR().then(function ()
                {
                    _mainSignalR.chatHub.invoke('broadcastRefresh', localUserGrpKeys).then(function ()
                    {
                        defer.resolve();
                    }, function ()
                    {
                        defer.reject()
                    });
                }, function ()
                {
                    defer.reject()
                });
            }
        }
        else
        {
            defer.reject();
        }

        return defer.promise;
    }

    signalRServiceFactory.startSignalR = _startSignalR;
    signalRServiceFactory.startMainSignalR = _startMainSignalR;

    signalRServiceFactory.stopMainSignalR = _stopMainSignalR;
    signalRServiceFactory.stopSignalR = _stopSignalR;

    signalRServiceFactory.sendMsg = _sendMsg;
    signalRServiceFactory.clearMsg = _clearMsg;
    signalRServiceFactory.clearSpecificMsg = _clearSpecificMsg;

    signalRServiceFactory.getUsageAnalytics = _getUsageAnalytics;
    signalRServiceFactory.postUsageAnalytics = _postUsageAnalytics;
    signalRServiceFactory.postFormUsage = _postFormUsage;
    signalRServiceFactory.postReportExecution = _postReportExecution;
    signalRServiceFactory.postScreenSize = _postScreenSize;
    signalRServiceFactory.postNetSpeed = _postNetSpeed;

    signalRServiceFactory.getProcStat = _getProcStat;
    signalRServiceFactory.getWorkFlowStat = _getWorkFlowStat;

    signalRServiceFactory.checkAlive = _checkAlive;
    signalRServiceFactory.keepAlive = _keepAlive;
    signalRServiceFactory.checkAppVersion = _checkAppVersion;

    signalRServiceFactory.refreshSession = _refreshSession;
    signalRServiceFactory.broadcastRefresh = _broadcastRefresh;

    $rootScope.getConnectionState = function (type)
    {
        try
        {
            if (type === "workflow")
            {
                return _workflowSignalR.connection.state;
            }
            else if (type === "procurement")
            {
                return _procurementSignalR.connection.state;
            }
            else if (type === "main")
            {
                return _mainSignalR.connection.state;
            }
            else
            {
                return $.signalR.connectionState.disconnected;
            }
        }
        catch (err)
        {
            return $.signalR.connectionState.disconnected;
        }
    };

    $rootScope.forcedReloadServer = function (type, target)
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('forcedReloadServer', type, target);
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('forcedReloadServer', type, target);
            });
        }
    }

    $rootScope.forcedLogoutServer = function (target)
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('forcedLogoutServer', target);
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('forcedLogoutServer', target);
            });
        }
    }

    $rootScope.postPerfDBLog = function (content)
    {
        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('postPerfDBLog', content);
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('postPerfDBLog', content);
            });
        }
    }

    $rootScope.updateMaster = function (type, key, PrimaryCol, updatetype)
    {
        let defer = $q.defer();

        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('updateMaster', type, key, PrimaryCol, updatetype).then(function (result)
            {
                defer.resolve(result);
            }, function ()
            {
                defer.reject();
            });
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('updateMaster', type, key, PrimaryCol, updatetype).then(function (result)
                {
                    defer.resolve(result);
                }, function ()
                {
                    defer.reject();
                });
            });
        }

        return defer.promise;
    }

    $rootScope.getAttachmentCount = function (RefKey, RefSubject)
    {
        return new Promise(function (resolve, reject)
        {
            if ((typeof RefKey == "number" || typeof RefKey == "string") && typeof RefSubject == "string")
            {
                let _refKey = +RefKey;
                let _refSubject = RefSubject.trim().toUpperCase();

                if (_refSubject == "")
                {
                    resolve(0);
                }

                if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
                {
                    _mainSignalR.chatHub.invoke('getAttachmentCount', _refKey, _refSubject).then(function (result)
                    {
                        resolve(result);
                    }, function (error)
                    {
                        resolve(0);
                    });
                }
                else
                {
                    _startMainSignalR().then(function ()
                    {
                        _mainSignalR.chatHub.invoke('getAttachmentCount', _refKey, _refSubject).then(function (result)
                        {
                            resolve(result);
                        }, function (error)
                        {
                            resolve(0);
                        });
                    }, function ()
                    {
                        resolve(0);
                    });
                }
            }
            else
            {
                resolve(0);
            }
        });
    }

    //type 0-whatsnew, 1-notification || action 0-read, 1-delete, 2-update_IsRead_column
    $rootScope.updateNotificationStats = function (type, action, isAdmin = false, key = -1)
    {
        let defer = $q.defer();

        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke('updateNotificationStats', type, action, isAdmin, key).then(function (result)
            {
                defer.resolve(result);
            }, function ()
            {
                defer.reject();
            });
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke('updateNotificationStats', type, action, isAdmin, key).then(function (result)
                {
                    defer.resolve(result);
                }, function ()
                {
                    defer.reject();
                });
            });
        }

        return defer.promise;
    }

    $rootScope.TokenDebug = function (type)
    {
        let defer = $q.defer();

        if (_mainSignalR.connection.state === $.signalR.connectionState.connected)
        {
            _mainSignalR.chatHub.invoke(type).then(function (result)
            {
                defer.resolve(result);
            }, function (err)
            {
                defer.reject(err);
            });
        }
        else
        {
            _startMainSignalR().then(function ()
            {
                _mainSignalR.chatHub.invoke(type).then(function (result)
                {
                    defer.resolve(result);
                }, function (err)
                {
                    defer.reject(err);
                });
            });
        }

        return defer.promise;
    }

    return signalRServiceFactory;
}]);